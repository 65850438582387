@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Faro';
  font-weight: 700;
  src: url('/fonts/Faro-BoldLucky.ttf') format('truetype');
}

@font-face {
  font-family: 'Faro';
  font-weight: 600;
  src: url('/fonts/Faro-SemiBoldLucky.ttf') format('truetype');
}

@font-face {
  font-family: 'Faro';
  font-weight: 500;
  src: url('/fonts/Faro-DisplayLucky.ttf') format('truetype');
}

@font-face {
  font-family: 'Faro';
  font-weight: 400;
  src: url('/fonts/Faro-RegularLucky.ttf') format('truetype');
}

@font-face {
  font-family: 'Faro';
  font-weight: 300;
  src: url('/fonts/Faro-LightLucky.ttf') format('truetype');
}

@layer base {
  html,
  body {
    font-family: Faro, serif;
    font-weight: 400;
    height: 100%;
  }
  body {
    margin: 0;
  }
}

@layer components {
  .border-1 {
    border-width: 1px;
  }
}

.text-input {
  @apply border-1 rounded-xl border-neutralGray focus:border-brandDarkPurple;
  @apply bg-neutralWhite;
  @apply text-neutralBlack placeholder-neutralGray;
  @apply px-3 py-1.5;
  @apply text-sm;
  @apply autofill:bg-neutralWhite;
  height: 35px;
}

.text-input:disabled {
  @apply cursor-not-allowed opacity-60;
  @apply bg-neutralGray/40;
}

.text-input-xs {
  @apply text-input;
  @apply text-xs;
}

.dark-input {
  color-scheme: dark;
}

.disabled-button {
  background-color: rgb(128, 128, 128) !important;
  color: white !important;
  cursor: not-allowed;
}

.btn {
  @apply flex cursor-pointer items-center bg-primary text-white;
  @apply px-6;
  @apply h-12;
  @apply rounded-xl;
  @apply hover:bg-secondary;
}

.btn-transparent {
  @apply btn;
  @apply border-1 border-white;
  @apply bg-neutralGray/20 hover:bg-opacity-5;
  @apply border-neutralGray/30;
}

.btn-transparent-auto {
  @apply btn-transparent;
  @apply h-auto;
}

.border-1 {
  border-width: 1px;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.PhoneInput input {
  background-color: transparent;
}

tr th:first-child {
  -moz-border-radius-topleft: 0.75rem;
  -moz-border-radius-bottomleft: 0.75rem;
  -webkit-border-top-left-radius: 0.75rem;
  -webkit-border-bottom-left-radius: 0.75rem;
}

tr th:last-child {
  -moz-border-radius-topright: 0.75rem;
  -moz-border-radius-bottomright: 0.75rem;
  -webkit-border-top-right-radius: 0.75rem;
  -webkit-border-bottom-right-radius: 0.75rem;
}

.custom-grid-layout-open {
  display: grid;
  grid-template-columns: 20em 1fr;
}

.custom-grid-layout-close {
  display: grid;
  grid-template-columns: 5.3em 1fr;
}

/* Remove arrows from number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  font-size: 16px;
  -webkit-user-select: auto;
  -webkit-touch-callout: none;
}

input[type='date'] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input::-webkit-date-and-time-value {
  text-align: left;
}
