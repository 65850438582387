.embla {
  margin: auto;
  --slide-height: 15rem;
  --slide-spacing: 1.5rem;
  --slide-size: 40%;
}
@media (max-width: 768px) {
  .embla {
    --slide-size: 100%;
  }
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__content {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1rem;
  height: var(--slide-height);
  user-select: none;
  overflow: hidden;
}
